import React from 'react';

const ProjectCard = ({ projects, maxCardsToShow }) => {
  console.log('Project Card:', projects);
  return (
    <>
      {projects.slice(0, maxCardsToShow).map((project) => (
        <article
          key={project.slug}
          className="card lg:card-side ring-1 ring-base-content/10 bg-base-100 p-7 rounded-3xl"
        >
          <a className="w-full" href={`/blog/${project.slug}`}>
            <img src={project?.image} className="w-full h-52 rounded-xl object-cover" alt={project.project} />
          </a>

          <div className="card-body p-0 lg:ml-7">
            <h2 className="text-xs lg:text-sm text-base-content/70">
              <span>{project.role}</span>
              <span className="text-xl font-bold text-primary">.</span>
              <time className="text-xs lg:text-sm text-base-content/70">{project.dates}</time>
            </h2>
            <a href={`/blog/${project.slug}`}>
              <h1 className="font-semibold text-lg lg:text-xl">{project.project}</h1>
            </a>

            <div>
              <p className="text-sm lg:text-base opacity-70 line-clamp-2 xl:line-clamp-3">{project.summary}</p>
            </div>

            <a href={`/blog/${project.slug}`} className="mt-auto flex items-center">
              <h2 className="text-sm font-semibold text-primary">Read more</h2>
              <svg
                viewBox="0 0 16 16"
                fill="none"
                aria-hidden="true"
                className="ml-1 h-4 w-4 stroke-current text-primary"
              >
                <path d="M6.75 5.75L9.25 8l-2.5 2.25" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </a>
          </div>
        </article>
      ))}
    </>
  );
};

export default ProjectCard;

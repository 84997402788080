import React from 'react';
import classNames from 'classnames'; // Optionally import classNames if you want to use a utility function

const Card = ({ title, content, children, divider, imageSrc, imageAlt, topMargin }) => {
  return (
    <>
      {divider && <div className="divider my-2" />}
      <div
        className={classNames('card md:card-side bg-base-100 text-wrap text-sm md:text-base', {
          'mt-4': topMargin,
          'md:mt-0': topMargin && imageSrc,
        })}
      >
        {imageSrc && (
          <figure className="md:w-1/3 lg:w-1/2">
            <img src={imageSrc} alt={imageAlt || 'Image'} className="md:h-full object-cover" />
          </figure>
        )}
        <div className={`card-body p-2 md:p-4 ${imageSrc ? 'md:w-2/3 lg:w-1/2' : 'w-full'}`}>
          {title && <h2 className="my-0 card-title">{title}</h2>}
          <p>{content}</p>
          {children}
        </div>
      </div>
    </>
  );
};

export default Card;

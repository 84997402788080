import * as React from 'react';
const Logo = (props) => (
  <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g transform="rotate(45 256 256)">
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        <path
          fill="#74bd6a"
          d="M324.3 34.79c-25.7 0-46.5 27.02-46.5 60.36 0 20.05 7.7 38.85 20.6 50.05 5.6 81.2-4.7 152.3-53.6 160.1-60.2 6.2-73.2-68.2-84.6-139 8.9-15.7 9.2-37.5.6-57.3-13.4-30.79-43.6-47.33-67.56-36.92-23.92 10.4-32.47 43.72-19.11 74.52 9.44 21.7 27.77 37.1 47.07 39.6 33.9 57.6 71.8 152.4 51 180.2-11.5 15.2-32.7 10.4-55.6-4 3.7-16.3-4.5-34.6-20.81-46.7-22.74-16.9-53.8-16.5-69.39.8-15.6 17.3-9.77 45 13 61.9 17.78 13.1 41.33 16.1 58.44 7.4 67.86 32.9 121.86 99.5 175.56 90.6 76.2-12.6 76.5-119.3 148.3-149.9 13.7 9.1 33.5 8.3 49.7-2.1 21.2-13.7 29.1-39.2 17.7-56.9-11.4-17.8-37.9-21.1-59.2-7.4-15.9 10.3-24.9 27.8-22.5 43.9-29.9 22.4-68.5 39.7-88.7 27.3-16.2-9.9 1.8-122.3 19-178.4 19.6-7.7 33.1-31.1 33.1-57.75 0-33.34-20.8-60.37-46.5-60.36z"
        ></path>
      </g>
    </g>
  </svg>
);
export default Logo;

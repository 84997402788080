import React from 'react';
import { FaLeaf, FaRecycle, FaCity, FaFrog } from 'react-icons/fa';
import Logo from './Logo';
import Hero from './Hero';
import Card from './Card';

const HomeHero = () => {
  const content = `
    Rana Habitat Group is an environmental consultancy using wildlife surveys and ecological knowledge to guide land management, wildlife connectivity planning, and habitat design projects.
  `;
  const textColor = 'text-primary-content';

  return (
    <Hero
      id="home-hero"
      bgClass="bg-base-100"
      content={content}
      backgroundImage="https://live.staticflickr.com/65535/50345744396_1e89b655c0_3k.jpg"
      textColor={textColor}
      overlayOpacity={10}
      attribution={
        <>
          <a href="https://flic.kr/p/2jGT6MN" target="_blank" rel="noopener noreferrer" className="text-xs lg:text-sm">
            Elk Creek Wild & Scenic River
          </a>{' '}
          by
          <a
            href="https://www.flickr.com/photos/blmoregon/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-xs lg:text-sm"
          >
            Bureau of Land Management Oregon and Washington
          </a>
          , on Flickr
        </>
      }
    >
      <div className="divider my-0" />
      <Logo width="100" height="100" alt="Logo frog" className="card mx-auto bg-secondary" />
      <Card
        content="Our studies inform project design and implementation for sustainable and ecologically-centered land
            management and wildlife conservation projects that are intentional, resilient, and inspired. We work with
            landowners, land managers, and project designers to craft ecologically sound projects."
        divider={true}
      />
      <Card
        title="We promote:"
        children={
          <ul className="my-0 list-none ">
            <li className="flex items-center">
              <FaLeaf className="text-secondary-content bg-secondary mr-2" />
              Conservation of wild land and habitat connectivity
            </li>
            <li className="flex items-center">
              <FaRecycle className="text-secondary-content bg-secondary mr-2" />
              Restoration of degraded lands
            </li>
            <li className="flex items-center">
              <FaFrog className="text-secondary-content bg-secondary mr-2" />
              Sustainability of critical wildlife species presence
            </li>
            <li className="flex items-center">
              <FaCity className="text-secondary-content bg-secondary mr-2" />
              Increasing native biodiversity within cities
            </li>
          </ul>
        }
        divider={true}
      />
      <Card content="Rana Habitat Group is a woman-owned business in Oregon State." divider={true} />
    </Hero>
  );
};

export default HomeHero;

import React from 'react';
import Hero from './Hero';
import ContactForm from './ContactForm';
import { FaEnvelope, FaLinkedin, FaPhoneAlt } from 'react-icons/fa';

const ContactHero = () => {
  return (
    <Hero
      id="contact-hero"
      backgroundImage="https://live.staticflickr.com/65535/49206119716_173dc2636e_3k.jpg"
      overlayOpacity={35}
      attribution={
        <>
          <a href="https://flic.kr/p/2hYbe5q" target="_blank" rel="noopener noreferrer" className="text-xs lg:text-sm">
            Jenny Creek Wild and Scenic River
          </a>{' '}
          by
          <a
            href="https://www.flickr.com/photos/blmoregon/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-xs lg:text-sm"
          >
            Bureau of Land Management Oregon and Washington
          </a>
          , on Flickr
        </>
      }
    >
      {/* Container for two columns */}
      <div className="flex flex-col md:flex-row justify-between md:space-x-10 md:h-full md:items-center">
        {/* Left Column: Contact Information */}
        <div className="card card-compact md:card-normal bg-base-100 bg-opacity-90 order-2 md:order-1 md:w-1/2  md:h-full my-4">
          <div className="card-body my-0">
            <div className="flex items-center">
              <FaEnvelope className="mr-2" />
              <a href="mailto:lguderyahn@gmail.com" className="text-lg">
                lguderyahn@gmail.com
              </a>
            </div>
            <div className="flex items-center">
              <FaPhoneAlt className="mr-2" />
              <a href="tel:+1-815-388-4803" className="text-lg">
                +1 (815) 388-4803
              </a>
            </div>
            <div className="flex items-center">
              <FaLinkedin className="mr-2" />
              <a
                href="https://www.linkedin.com/in/lauraguderyahn/"
                className="text-lg"
                target="_blank"
                rel="noopener noreferrer"
              >
                LinkedIn Profile
              </a>
            </div>
          </div>
        </div>
        {/* Right Column: Contact Form */}
        <div className="order-1 md:order-2 md:w-1/2">
          <ContactForm />
        </div>
      </div>

      {/* Scroll Up Arrow */}
      <div className="mt-8 text-center">
        <a href="#home-hero">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-10 w-10 mx-auto animate-bounce-once"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-label="Up arrow"
          >
            <path
              transform="rotate(180, 12, 12)"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </a>
      </div>
    </Hero>
  );
};

export default ContactHero;

import React from 'react';
import ThemeSwitcher from './ThemeSwitcher';
import Logo from './Logo';

const NavigationBar = ({ navLinks, selectedSection, onSectionChange }) => {
  const params = new URLSearchParams(window.location.search);
  const themeSelector = params.get('showThemeSelector');

  return (
    <nav className="navbar bg-transparent fixed top-0 w-full z-50">
      <div className="container mx-auto flex justify-between items-center">
        <div className="flex items-center space-x-2 bg-primary bg-opacity-90 p-2 rounded-lg">
          <Logo
            className="h-8 w-8 bg-secondary rounded-lg"
            alt="An illustration of a green frog's foot with four smooth, rounded toes on a transparent background."
            title="Rana Habitat Group logo"
          />
          <div className="text-md lg:text-2xl text-primary-content font-bold">Rana Habitat Group</div>
        </div>
        <div className="dropdown dropdown-end lg:hidden">
          <label tabIndex="0" className="btn btn-secondary bg-opacity-70 lg:hidden">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
            </svg>
          </label>
          <ul tabIndex="0" className="menu menu-sm dropdown-content mt-3 p-2 shadow  w-52">
            {navLinks.map((link) => (
              <li key={link.href}>
                <a
                  href={link.href}
                  onClick={() => onSectionChange(link.href)}
                  className={
                    link.href === selectedSection ? 'btn  btn-secondary btn-sm btn-active' : 'btn  btn-secondary btn-sm'
                  }
                >
                  {link.label}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className="hidden lg:flex space-x-4">
          {navLinks.map((link) => (
            <a
              key={link.href}
              href={link.href}
              onClick={() => onSectionChange(link.href)}
              className={
                link.href === selectedSection ? 'btn btn-primary btn-sm btn-active' : 'btn btn-secondary btn-sm'
              }
            >
              {link.label}
            </a>
          ))}
          {themeSelector === 'true' && <ThemeSwitcher />}
        </div>
      </div>
    </nav>
  );
};

export default NavigationBar;

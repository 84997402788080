import React from 'react';
import Card from './Card';

const Hero = ({
  id,
  title,
  content,
  cardImage,
  children,
  bgClass = 'bg-base-200',
  backgroundImage,
  // textColor = 'text-primary-content',
  overlayOpacity = 20,
  attribution,
  containerClass = 'max-w-screen-l max-w-sm md:max-w-lg lg:max-w-xl mx-auto',
}) => {
  return (
    <header
      id={id}
      className={`relative min-h-screen flex items-center justify-center ${bgClass}`}
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      {/* Apply the dynamic overlay opacity */}
      <div
        className="absolute inset-0 bg-black"
        style={{
          opacity: overlayOpacity / 100, // Convert percentage to decimal
        }}
      />

      <article className={`relative hero-content px-4 py-2 mt-12 text-wrap text-sm md:text-base`}>
        <div className={containerClass}>
          {content && <Card content={content} title={title} imageSrc={cardImage} topMargin={true} />}
          {children}
        </div>
      </article>

      {/* Attribution */}
      <div className="divider my-0" />
      {attribution && (
        <div
          className="absolute bottom-3 right-5 text-xs text-opacity-50 text-neutral-content bg-neutral bg-opacity-50 transform origin-bottom-right"
          style={{
            transform: 'rotate(90deg)',
          }}
        >
          {attribution}
        </div>
      )}
    </header>
  );
};

export default Hero;

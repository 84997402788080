import React, { useState, useEffect } from 'react';
import Hero from './Hero';
import { csv } from 'd3-fetch';
import ProjectCard from './ProjectCard';

const ProjectHero = ({ title, path }) => {
  const id = title.toLowerCase().replace(/[^a-z0-9]+/g, '-') + '-hero';
  const [data, setData] = useState([]);

  useEffect(() => {
    csv(path).then((data) => {
      setData(data);

      console.log('Project data:', data);
    });
  }, [path]);

  return (
    <Hero
      id={id}
      title={title}
      bgClass="bg-base-200"
      containerClass="max-w-full mx-auto"
      backgroundImage="https://live.staticflickr.com/65535/49205629363_31265900b2_3k.jpg"
      attribution={
        <>
          <a href="https://flic.kr/p/2hY8Hj4" target="_blank" rel="noopener noreferrer" className="text-xs lg:text-sm">
            Jenny Creek Wild and Scenic River
          </a>{' '}
          by
          <a
            href="https://www.flickr.com/photos/blmoregon/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-xs lg:text-sm"
          >
            Bureau of Land Management Oregon and Washington
          </a>
          , on Flickr
        </>
      }
    >
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <ProjectCard projects={data} maxCardsToShow={6} />
      </div>
    </Hero>
  );
};

export default ProjectHero;

// ContactForm.jsx
import React, { useState } from 'react';

const ContactForm = () => {
  const [status, setStatus] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const form = event.target;
    const formData = new FormData(form);

    try {
      const response = await fetch('https://getform.io/f/rbeqqpqb', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        setStatus('success');
        form.reset();
      } else {
        setStatus('error');
      }
    } catch (error) {
      setStatus('error');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="card card-compact md:card-normal bg-primary bg-opacity-90">
        <div className="card-body">
          <h3 className="card-title my-0">Get in Touch</h3>
          <label className="input input-sm md:input flex items-center gap-2">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" className="h-4 w-4 bg-secondary">
              <path d="M2.5 3A1.5 1.5 0 0 0 1 4.5v.793c.026.009.051.02.076.032L7.674 8.51c.206.1.446.1.652 0l6.598-3.185A.755.755 0 0 1 15 5.293V4.5A1.5 1.5 0 0 0 13.5 3h-11Z" />
              <path d="M15 6.954 8.978 9.86a2.25 2.25 0 0 1-1.956 0L1 6.954V11.5A1.5 1.5 0 0 0 2.5 13h11a1.5 1.5 0 0 0 1.5-1.5V6.954Z" />
            </svg>
            <input
              type="email"
              name="email"
              className="grow input input-sm"
              placeholder="Email"
              disabled={isLoading}
              required
            />
          </label>
          <label className="input input-sm md:input flex items-center gap-2">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" className="h-4 w-4 bg-secondary">
              <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM12.735 14c.618 0 1.093-.561.872-1.139a6.002 6.002 0 0 0-11.215 0c-.22.578.254 1.139.872 1.139h9.47Z" />
            </svg>
            <input
              type="text"
              name="name"
              className="grow input input-sm"
              placeholder="Name"
              disabled={isLoading}
              required
            />
          </label>
          <div className="flex items-center gap-2">
            <textarea
              name="message"
              placeholder="Message"
              className="grow textarea textarea-bordered textarea-sm md:textarea-lg"
              disabled={isLoading}
            ></textarea>
          </div>
          {/* Honeypot input to prevent spam */}
          <input type="hidden" name="_gotcha" style={{ display: 'none !important' }} />
          <div className="card-actions form-control justify-center">
            <button type="submit" className={`btn btn-secondary text-secondary-content btn-sm md:btn`}>
              {isLoading ? 'Sending' : 'Send'}
            </button>
          </div>
        </div>
      </form>
      <div className="toast toast-top toast-center">
        {status === 'success' && (
          <div className="alert alert-success">
            <span>Message sent successfully!</span>
          </div>
        )}
        {status === 'error' && (
          <div className="alert alert-error">
            <span>There was a problem. Please send an email to lguderyahn@gmail.com.</span>
          </div>
        )}
      </div>
    </>
  );
};

export default ContactForm;

import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import SectionHero from './components/SectionHero';
import HomeHero from './components/HomeHero';
import ContactHero from './components/ContactHero';
import AboutHero from './components/AboutHero';
import CommingSoonHero from './components/CommingSoonHero';
import NavigationBar from './components/NavigationBar';
import ProjectHero from './components/ProjectHero';
import './App.css';

const defaultTheme = process.env.REACT_APP_DEFAULT_THEME;

function App() {
  const [isContentVisible, setIsContentVisible] = useState(false);
  const [selectedSection, setSelectedSection] = useState(window.location.hash || '#home');

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', defaultTheme);

    const params = new URLSearchParams(window.location.search);
    const showContent = params.get('showContent');

    if (showContent === 'true') {
      setIsContentVisible(true);
    }

    setSelectedSection(window.location.hash);
  }, []);

  const navLinks = [
    { href: '#home', label: 'Home' },
    { href: '#about', label: 'About Us' },
    { href: '#projects', label: 'Projects' },
    { href: '#contact', label: 'Contact' },
  ];

  const handleSectionChange = (section) => {
    setSelectedSection(section);
    window.history.pushState(null, null, section);
  };

  const [homeRef, homeInView] = useInView({ threshold: 0.5 });
  const [aboutRef, aboutInView] = useInView({ threshold: 0.5 });
  const [projectsRef, projectsInView] = useInView({ threshold: 0.1 });
  const [contactRef, contactInView] = useInView({ threshold: 0.5 });

  useEffect(() => {
    if (homeInView) handleSectionChange('#home');
    else if (aboutInView) handleSectionChange('#about');
    else if (projectsInView) handleSectionChange('#projects');
    else if (contactInView) handleSectionChange('#contact');
  }, [homeInView, aboutInView, projectsInView, contactInView]);

  return (
    <div className="App">
      <NavigationBar navLinks={navLinks} selectedSection={selectedSection} onSectionChange={handleSectionChange} />

      <div className="h-screen overflow-y-scroll md:nap-y snap-mandatory">
        {!isContentVisible && (
          <div className="md:snap-center">
            <CommingSoonHero />
          </div>
        )}
        {isContentVisible && (
          <>
            <div className="md:snap-center" id="home" ref={homeRef}>
              <HomeHero />
            </div>
            <div className="md:snap-center" id="about" ref={aboutRef}>
              <AboutHero />
            </div>
            <div className="md:snap-center" id="projects" ref={projectsRef}>
              <ProjectHero title="Projects" path="/content/projects.csv" />
            </div>
            <div className="md:snap-center" id="contact" ref={contactRef}>
              <ContactHero />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default App;
